import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import NotFoundPage from '../pages/404'
import Video from '../components/global/video'

const Watch = ({ pageContext }) => {
  const expiresOn = new Date(pageContext.expires)

  if (expiresOn < Date.now()) {
    return <NotFoundPage />
  }

  const { title, src, expiresDisplay } = pageContext

  return (
    <Layout>
      <SEO title="Watch" />
      <div className="container container-last">
        <h1>Watch: {title}</h1>
        <p className="meta">This link will expire on {expiresDisplay}.</p>
        <Video title={title} src={src} />
      </div>
    </Layout>
  )
}

export default Watch
